<template>
  <div
    :class="`popup-content ${position} ${showPopup ? 'show' : ''}`"
    :style="{
      [position == 'top' ? 'bottom' : 'top']: `${100 + offsetY}%`,
      left: fixedX ? 'unset' : `${offsetX + 50}%`,
      right: fixedX || 'unset',
      '--offset-arrow': `${offsetArrow + 50}%`
    }"
    :id="step"
  >
    <a @click="closePopup" class="close-icon">
      <i class="fas fa-times"></i>
    </a>
    <h6 class="title is-6 margin-10" :data-testid="`popup-title-${step}`">
      {{ title }}
    </h6>
    <div class="margin-10" :data-testid="`popup-text-${step}`">
      {{ text }}
    </div>
    <div>
      <a
        @click="$emit('prev-action')"
        v-if="step > 0"
        class="button is-small is-primary is-outlined"
        :data-testid="`popup-previous-button-${step}`"
      >
        PREVIOUS STEP
      </a>
      &nbsp;
      <a
        @click="$emit('next-action')"
        class="button is-small is-primary is-outlined"
        :data-testid="`popup-next-button-${step}`"
      >
        NEXT STEP
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import STEPS from "../constants/steps";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    step: { type: Number, required: true },
    showPopup: { type: Boolean, required: true },
    position: { type: String, required: true },
    offsetX: { type: Number, required: false, default: 0 },
    offsetY: { type: Number, required: false, default: 0 },
    fixedX: { type: String, required: false, default: null },
    offsetArrow: { type: Number, required: false, default: 0 }
  },
  setup(props) {
    const store = useStore();

    const closePopup = () => {
      store.commit("updateStep", null);
    };

    return {
      ...STEPS[props.step],
      closePopup
    };
  }
});
</script>
<style scoped>
/* The actual popup */
.popup-content {
  visibility: hidden;
  width: 300px;
  margin-left: -150px;
  text-align: center;
  padding: 20px;
  position: absolute;
  z-index: 1;
  color: #fff;
  border: 1px solid #ffffff;
  background: #1a1a1a;
  box-shadow: 0px 5px 30px rgba(255, 255, 255, 0.25);
}

.close-icon {
  position: absolute;
  right: 0;
  top: 0;
  color: white;
  padding: 10px 15px;
}

.close-icon:hover {
  color: grey;
}

.top::after {
  content: "";
  position: absolute;
  top: 100%;
  left: var(--offset-arrow);
  margin-left: -6.5px;
  border-width: 13px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
}

.bottom::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: var(--offset-arrow);
  margin-left: -6.5px;
  border-width: 13px;
  border-style: solid;
  border-color: transparent transparent #ffffff transparent;
}

/* Toggle this class - hide and show the popup */
.show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.margin-10 {
  margin: 10px !important;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
