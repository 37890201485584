
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref
} from "vue";
import { getLink } from "@/utils/utils";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Popup from "./Popup.vue";

interface User {
  id: string;
  username: string;
  hashedUsername: string;
  email: string;
}

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<User> | null,
      required: false,
      default: null
    },
    loading: Boolean
  },
  components: {
    Popup
  },
  setup(props) {
    const link = ref<string | null>(null);
    const isActive = ref<string>("");
    const router = useRouter();
    const dropdownIsActive = ref<string>("");
    const store = useStore();
    const currentStep = computed(() => store.state.step);

    onMounted(async () => {
      if (props.user != null) {
        link.value = await getLink(props.user.hashedUsername);
      }
    });

    const route = (url: string) => {
      isActive.value = "";
      router.push(url);
    };

    const logout = () => {
      localStorage.removeItem("token");
      window.location.reload();
    };

    const toggleIsActive = () =>
      (isActive.value = isActive.value === "" ? "is-active" : "");

    const toggleDropdownIsActive = () =>
      (dropdownIsActive.value =
        dropdownIsActive.value === "" ? "is-active" : "");

    const windowWidth = ref(window.innerWidth);

    const resizeScreen = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(async () => {
      window.addEventListener("resize", resizeScreen);
      window.addEventListener("click", () => {
        dropdownIsActive.value = "";
      });
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", resizeScreen);
    });

    const prevAction = () => {
      const element = document.getElementById("scrollTo-0");
      if (element && windowWidth.value <= 768) {
        element.scrollIntoView();
      }
      store.commit("updateStep", currentStep.value - 1);
    };

    const nextAction = () => {
      router.push("/profile");
      store.commit("updateStep", currentStep.value + 1);
    };

    return {
      link,
      isActive,
      toggleIsActive,
      logout,
      route,
      toggleDropdownIsActive,
      dropdownIsActive,
      windowWidth,
      currentStep,
      prevAction,
      nextAction
    };
  }
});
