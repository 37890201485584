
import { computed, defineComponent } from "vue";
import HomeLogged from "../components/HomeLogged.vue";
import HomeNotLogged from "../components/HomeNotLogged.vue";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    HomeLogged,
    HomeNotLogged
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.state.user);

    return {
      isLogged: user.value != null
    };
  },
  name: "Home"
});
