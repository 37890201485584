import gql from "graphql-tag";

export const SEND_MESSAGE_MUTATION = gql`
  mutation NewMessage($input: NewMessageInput!) {
    newMessage(input: $input) {
      from
      to
      text
      read
      created_at
    }
  }
`;

export const MESSAGES_SUBSCRIPTION = gql`
  subscription onMessageAdded {
    messageAdded {
      to
      from
      text
      read
      created_at
    }
  }
`;

export const MESSAGES_QUERY = gql`
  query Messages {
    messages {
      to
      from
      text
      read
      created_at
    }
  }
`;

export const UNREAD_MESSAGES_QUERY = gql`
  query UnreadMessages {
    unreadMessages {
      to
      from
      text
      read
      created_at
    }
  }
`;

export const UPDATE_MESSAGE = gql`
  mutation UpdateMessage($from: String!) {
    updateMessage(from: $from) {
      from
      to
      text
      read
      created_at
    }
  }
`;

export const READ_MESSAGES_SUBSCRIPTION = gql`
  subscription ReadMessages {
    readMessages {
      to
      from
      text
      read
      created_at
    }
  }
`;

export const DELETE_CONVERSATION = gql`
  mutation DeleteConv($interlocutor: String!) {
    deleteConv(interlocutor: $interlocutor) {
      to
      from
      text
      read
      created_at
    }
  }
`;
