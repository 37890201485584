<template>
  <div class="center">
    <div class="loader" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home"
});
</script>

<style scoped>
.loader {
  border: 10px solid #363636;
  border-top: 10px solid #ff1e3f;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

.center {
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  position: fixed;
  justify-content: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
