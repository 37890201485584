
import { defineComponent, ref } from "vue";
import { useMutation } from "@vue/apollo-composable";
import { SEND_RESET_PASSWORD_MUTATION } from "../constants/gql/user";

export default defineComponent({
  props: {
    active: { type: Boolean, required: true }
  },
  setup(_, context) {
    const email = ref<string>("");
    const error = ref<string>("");
    const { mutate } = useMutation(SEND_RESET_PASSWORD_MUTATION);

    const sendEmail = async () => {
      if (email.value != "") {
        await mutate({ email: email.value });
        context.emit("toggle-reset");
      } else {
        error.value = "This email is invalid";
      }
    };

    return {
      email,
      sendEmail,
      error
    };
  }
});
