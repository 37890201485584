
import { defineComponent, computed, ref } from "vue";
import { useQuery, useResult, useMutation } from "@vue/apollo-composable";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { FIND_CFT_QUERY } from "@/constants/gql/ctf";
import CtfCard from "./CtfCard.vue";
import Loader from "./Loader.vue";
import Popup from "./Popup.vue";
import { UPDATE_GUIDED_TOUR_MUTATION } from "../constants/gql/user";

const MOST_POPULAR_CTF_TITLES = [
  "hackthebox",
  "hacker101",
  "hack-me",
  "root-me"
];

interface PartialCtf {
  id: string;
  title: string;
}

export default defineComponent({
  name: "Home",
  components: {
    CtfCard,
    Loader,
    Popup
  },
  setup() {
    const router = useRouter();
    const { mutate } = useMutation(UPDATE_GUIDED_TOUR_MUTATION);
    const store = useStore();
    const searchedCtfs = ref<string>("");
    const showPopup = ref<boolean>(true);
    const currentStep = computed(() => store.state.step);
    const showModal = ref<boolean>(store.state.user.guidedTourIsActive);
    const username = ref<string>(store.state.user.username.toUpperCase());

    const { result, loading } = useQuery(FIND_CFT_QUERY);
    const ctfs = useResult(result);

    const user = computed(() => store.state.user);
    const myCtfIds = computed(() => user.value.ctfs);

    const myCtfs = computed(() =>
      ctfs.value?.filter((el: PartialCtf) => myCtfIds.value.includes(el.id))
    );
    const mostPopularCtfs = computed(() =>
      ctfs.value?.filter((el: PartialCtf) =>
        MOST_POPULAR_CTF_TITLES.includes(el.title.toLowerCase())
      )
    );
    const ctfsToDiscover = computed(() =>
      ctfs.value?.filter(
        (el: PartialCtf) =>
          !myCtfIds.value.includes(el.id) &&
          !MOST_POPULAR_CTF_TITLES.includes(el.title.toLowerCase())
      )
    );

    const findSearchedCtfs = () => {
      const search = (search = "", value: string) => {
        return (value || "").toLowerCase().indexOf(search.toLowerCase()) !== -1;
      };

      return ctfs.value?.filter((el: PartialCtf) => {
        return search(searchedCtfs.value, el.title);
      });
    };

    const setStep = async (value: boolean) => {
      showModal.value = false;
      if (value) {
        store.commit("updateStep", 0);
        const element = document.getElementById("scrollTo-0");
        if (element) {
          element.scrollIntoView();
        }
      }

      const { data } = await mutate();
      store.commit("setUser", data.updateGuidedTour);
    };

    const nextAction = (id: string) => {
      store.commit("updateStep", currentStep.value + 1);
      router.push(`/ctf/${id}`);
    };

    return {
      loading,
      showPopup,
      showModal,
      currentStep,
      setStep,
      nextAction,
      username,
      myCtfs,
      mostPopularCtfs,
      ctfsToDiscover,
      searchedCtfs,
      findSearchedCtfs
    };
  }
});
