export default function signupValidationInput(
  username: string,
  email: string,
  level: string,
  password: string,
  confirmPassword: string,
  invitationCode: string
) {
  const validations = {
    usernameIsValid: false,
    usernameIsInvalid: false,
    emailIsValid: false,
    emailIsInvalid: false,
    passwordIsInvalid: false,
    confirmPasswordIsInvalid: false,
    levelIsInvalid: false,
    invitationCodeIsInvalid: false
  };

  if (username.length > 2) {
    validations.usernameIsValid = true;
    validations.usernameIsInvalid = false;
  } else {
    validations.usernameIsValid = false;
    validations.usernameIsInvalid = true;
  }

  if (email.length > 2) {
    validations.emailIsValid = true;
    validations.emailIsInvalid = false;
  } else {
    validations.emailIsValid = false;
    validations.emailIsInvalid = true;
  }

  if (password.length >= 8) {
    validations.passwordIsInvalid = false;
  } else {
    validations.passwordIsInvalid = true;
  }

  if (password === confirmPassword && confirmPassword.length >= 8) {
    validations.confirmPasswordIsInvalid = false;
  } else {
    validations.confirmPasswordIsInvalid = true;
  }

  if (level == "" || level == "What's your level ?") {
    validations.levelIsInvalid = true;
  } else {
    validations.levelIsInvalid = false;
  }

  if (invitationCode.length <= 5) {
    validations.invitationCodeIsInvalid = false;
  } else {
    validations.invitationCodeIsInvalid = true;
  }

  return validations;
}
