
import { defineComponent, ref } from "vue";
import { useMutation } from "@vue/apollo-composable";
import { CREATE_USER_MUTATION } from "../constants/gql/user";
import signupValidationInput from "../utils/signupValidationInput";
import { ARRAY_OF_LEVEL } from "../constants/level";

export default defineComponent({
  props: {
    active: { type: Boolean, required: true }
  },
  setup(_, context) {
    const isDeveloper = ref<boolean>(false);
    const hiddenValue = ref<string>("");
    const hiddenValueError = ref<string>("");
    const username = ref<string>("");
    const usernameIsValid = ref<boolean>(false);
    const usernameIsInvalid = ref<boolean>(false);
    const email = ref<string>("");
    const emailIsValid = ref<boolean>(false);
    const emailIsInvalid = ref<boolean>(false);
    const password = ref<string>("");
    const passwordIsInvalid = ref<boolean>(false);
    const confirmPassword = ref<string>("");
    const confirmPasswordIsInvalid = ref<boolean>(false);
    const invitationCode = ref<string>("");
    const level = ref<string>("What's your level ?");
    const arrayOfLevel = ARRAY_OF_LEVEL;
    const levelIsInvalid = ref<boolean>(false);
    const invitationCodeIsInvalid = ref<boolean>(false);
    const validationEmail = ref<boolean>(false);
    const { mutate } = useMutation(CREATE_USER_MUTATION);

    async function register() {
      try {
        const { data: userData } = await mutate({
          input: {
            username: username.value,
            password: password.value,
            email: email.value,
            level: level.value,
            invitationCode: invitationCode.value
          }
        });
        if (userData.create != null && userData.create == "success") {
          validationEmail.value = true;
          return null;
        } else {
          return userData;
        }
      } catch (er) {
        return { create: "Email already exist" };
      }
    }

    async function signup() {
      const validationFields = signupValidationInput(
        username.value,
        email.value,
        level.value,
        password.value,
        confirmPassword.value,
        invitationCode.value
      );

      levelIsInvalid.value = validationFields.levelIsInvalid;
      passwordIsInvalid.value = validationFields.passwordIsInvalid;
      confirmPasswordIsInvalid.value =
        validationFields.confirmPasswordIsInvalid;
      emailIsInvalid.value = validationFields.emailIsInvalid;
      usernameIsInvalid.value = validationFields.usernameIsInvalid;
      invitationCodeIsInvalid.value = validationFields.invitationCodeIsInvalid;

      if (
        levelIsInvalid.value == false &&
        passwordIsInvalid.value == false &&
        confirmPasswordIsInvalid.value == false &&
        emailIsInvalid.value == false &&
        usernameIsInvalid.value == false &&
        invitationCodeIsInvalid.value == false
      ) {
        const data = await register();

        if (data) {
          if (data.create == "Username and Email already exist") {
            emailIsValid.value = false;
            emailIsInvalid.value = true;
            usernameIsValid.value = false;
            usernameIsInvalid.value = true;
          } else if (data.create == "Email already exist") {
            emailIsValid.value = false;
            emailIsInvalid.value = true;
          } else if (data.create == "Username already exist") {
            usernameIsValid.value = false;
            usernameIsInvalid.value = true;
          }
        }
      }
    }

    const closeModal = () => {
      validationEmail.value = false;
      context.emit("toggle-sign-up");
    };

    const enter = () => {
      if (hiddenValue.value === "8296216926242792255914442993") {
        isDeveloper.value = true;
        hiddenValueError.value = "";
      } else {
        hiddenValueError.value = "This is not the value, please try again";
      }
    };

    return {
      username,
      usernameIsValid,
      usernameIsInvalid,
      email,
      emailIsValid,
      emailIsInvalid,
      password,
      passwordIsInvalid,
      confirmPassword,
      confirmPasswordIsInvalid,
      invitationCode,
      invitationCodeIsInvalid,
      level,
      levelIsInvalid,
      arrayOfLevel,
      validationEmail,
      signup,
      closeModal,
      isDeveloper,
      hiddenValue,
      hiddenValueError,
      enter
    };
  }
});
