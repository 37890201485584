import gql from "graphql-tag";

export const FIND_BY_CTF_QUERY = gql`
  query FindByCtf($id: String!, $offset: Float!, $limit: Float!) {
    findByCtf(id: $id, offset: $offset, limit: $limit) {
      id
      username
      hashedUsername
      level
      description
      language
      skills
    }
  }
`;

export const USERS_IN_CTF_QUERY = gql`
  query UsersInCtfLength($id: String!) {
    usersInCtfLength(id: $id)
  }
`;

export const ADD_USER_CTF_MUTATION = gql`
  mutation AddUserCtf($ctf: String!) {
    addUserCtf(ctf: $ctf) {
      id
      username
      email
      level
      description
      ctfs
    }
  }
`;

export const DELETE_USER_CTF_MUTATION = gql`
  mutation DeleteUserCtf($ctf: String!) {
    deleteUserCtf(ctf: $ctf) {
      id
      username
      email
      level
      description
      ctfs
    }
  }
`;

export const FIND_CFT_QUERY = gql`
  query FindCtf($ctfId: String) {
    findCtf(ctfId: $ctfId) {
      id
      image
      title
      subtitle
      members
      url
    }
  }
`;
