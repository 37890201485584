
import { defineComponent, ref } from "vue";
import { useMutation } from "@vue/apollo-composable";
import { LOGIN_MUTATION } from "../constants/gql/user";

export default defineComponent({
  props: {
    active: { type: Boolean, required: true }
  },
  setup(_, context) {
    const email = ref<string>("");
    const password = ref<string>("");
    const error = ref<boolean>(false);
    const { mutate } = useMutation(LOGIN_MUTATION);

    const login = async () => {
      if (email.value.length > 0 && password.value.length > 0) {
        try {
          const { data } = await mutate({
            input: { email: email.value, password: password.value }
          });
          if (data != null && typeof data.login == "string") {
            localStorage.setItem("token", data.login);
            window.location.reload();
          } else {
            error.value = true;
          }
          context.emit("toggle-login");
        } catch (er) {
          error.value = true;
        }
      } else {
        error.value = true;
      }
    };

    const showPasswordDialog = () => {
      context.emit("toggle-login");
      context.emit("toggle-reset");
    };

    const showSignupDialog = () => {
      context.emit("toggle-login");
      context.emit("toggle-sign-up");
    };

    return {
      email,
      password,
      login,
      error,
      showPasswordDialog,
      showSignupDialog
    };
  }
});
