
import { REGISTER_NEWSLETTER } from "../constants/gql/newsletter";
import { FIND_CFT_QUERY } from "../constants/gql/ctf";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import CtfCard from "./CtfCard.vue";
import {
  MENTORING_DESKTOP_IMAGE_URL,
  MENTORING_MOBILE_IMAGE_URL,
  DISCOVER_DESKTOP_IMAGE,
  DISCOVER_MOBILE_IMAGE
} from "@/constants/imageUrls";

export default defineComponent({
  name: "Home",
  components: {
    CtfCard
  },
  setup() {
    const { mutate } = useMutation(REGISTER_NEWSLETTER);
    const email = ref<string>("");
    const register = ref<string>("");

    async function newsletter() {
      const { data: userData } = await mutate({
        input: {
          email: email.value
        }
      });
      email.value = "";
      register.value = userData.registerNewsletter;
    }

    const windowWidth = ref(window.innerWidth);

    const resizeScreen = () => {
      windowWidth.value = window.innerWidth;
    };
    onMounted(async () => {
      window.addEventListener("resize", resizeScreen);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", resizeScreen);
    });

    const { result: ctfCards } = useQuery(FIND_CFT_QUERY);

    const onBecomeAMentorClick = () => {
      window.open("https://ds6yzxacri2.typeform.com/to/LOuyW0G4", "_blank");
    };

    return {
      ctfCards,
      windowWidth,
      DISCOVER_DESKTOP_IMAGE,
      DISCOVER_MOBILE_IMAGE,
      MENTORING_DESKTOP_IMAGE_URL,
      MENTORING_MOBILE_IMAGE_URL,
      onBecomeAMentorClick,
      newsletter,
      email,
      register
    };
  }
});
