import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 2 }
const _hoisted_2 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navbar = _resolveComponent("navbar")
  const _component_router_view = _resolveComponent("router-view")
  const _component_sign_up_dialog = _resolveComponent("sign-up-dialog")
  const _component_login_dialog = _resolveComponent("login-dialog")
  const _component_reset_dialog = _resolveComponent("reset-dialog")

  return (_openBlock(), _createBlock("div", null, [
    (_ctx.user == null)
      ? (_openBlock(), _createBlock(_component_navbar, {
          key: 0,
          user: _ctx.user,
          loading: _ctx.loading,
          onToggleSignUp: _ctx.toggleSignUpDialog,
          onToggleLogin: _ctx.toggleLoginDialog
        }, null, 8, ["user", "loading", "onToggleSignUp", "onToggleLogin"]))
      : (_ctx.user != null)
        ? (_openBlock(), _createBlock(_component_navbar, {
            key: 1,
            user: _ctx.user,
            loading: _ctx.loading,
            onToggleSignUp: _ctx.toggleSignUpDialog,
            onToggleLogin: _ctx.toggleLoginDialog
          }, null, 8, ["user", "loading", "onToggleSignUp", "onToggleLogin"]))
        : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock("div", _hoisted_1))
      : (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode(_component_router_view, {
            refetch: _ctx.refetch,
            onToggleSignUp: _ctx.toggleSignUpDialog,
            onToggleLogin: _ctx.toggleLoginDialog
          }, null, 8, ["refetch", "onToggleSignUp", "onToggleLogin"]),
          (_ctx.user == null)
            ? (_openBlock(), _createBlock(_component_sign_up_dialog, {
                key: 0,
                active: _ctx.isActiveSignUpDialog,
                onToggleSignUp: _ctx.toggleSignUpDialog
              }, null, 8, ["active", "onToggleSignUp"]))
            : _createCommentVNode("", true),
          (_ctx.user == null)
            ? (_openBlock(), _createBlock(_component_login_dialog, {
                key: 1,
                active: _ctx.isActiveLoginDialog,
                onToggleLogin: _ctx.toggleLoginDialog,
                onToggleReset: _ctx.toggleResetDialog,
                onToggleSignUp: _ctx.toggleSignUpDialog
              }, null, 8, ["active", "onToggleLogin", "onToggleReset", "onToggleSignUp"]))
            : _createCommentVNode("", true),
          (_ctx.user == null)
            ? (_openBlock(), _createBlock(_component_reset_dialog, {
                key: 2,
                active: _ctx.isActiveResetDialog,
                onToggleReset: _ctx.toggleResetDialog
              }, null, 8, ["active", "onToggleReset"]))
            : _createCommentVNode("", true)
        ]))
  ]))
}