import gql from "graphql-tag";

export const LOGIN_MUTATION = gql`
  mutation Login($input: UserInput!) {
    login(input: $input)
  }
`;

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      id
    }
  }
`;

export const UPDATE_PROFILE_MUTATION = gql`
  mutation Update($input: UpdateInput!) {
    update(input: $input) {
      id
      username
      hashedUsername
      email
      level
      description
      ctfs
      language
      skills
      guidedTourIsActive
    }
  }
`;

export const UPDATE_GUIDED_TOUR_MUTATION = gql`
  mutation UpdateGuidedTour {
    updateGuidedTour {
      id
      username
      hashedUsername
      email
      level
      description
      ctfs
      language
      skills
      guidedTourIsActive
    }
  }
`;

export const CREATE_USER_MUTATION = gql`
  mutation Create($input: CreateUserInput!) {
    create(input: $input)
  }
`;

export const EMAIL_VALIDATION_MUTATION = gql`
  mutation EmailValidation($validationHash: String!) {
    emailValidation(validationHash: $validationHash)
  }
`;

export const SEND_RESET_PASSWORD_MUTATION = gql`
  mutation SendResetPassword($email: String!) {
    sendResetPassword(email: $email)
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($newPassword: String!, $validationHash: String!) {
    resetPassword(newPassword: $newPassword, validationHash: $validationHash)
  }
`;

export const WHOAMI_QUERY = gql`
  query Whoami {
    whoami {
      id
      username
      hashedUsername
      email
      level
      description
      ctfs
      language
      skills
      guidedTourIsActive
    }
  }
`;

export const FIND_USERS = gql`
  query FindUsers($usernames: [String!]!) {
    findUsers(usernames: $usernames) {
      username
      hashedUsername
    }
  }
`;

export const FIND_ONE_USER = gql`
  query FindOneUser($username: String!) {
    findOneUser(username: $username) {
      id
      username
      hashedUsername
      level
      description
      ctfs
      language
      skills
      guidedTourIsActive
    }
  }
`;
