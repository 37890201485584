
import { defineComponent } from "vue";

export default defineComponent({
  name: "CtfCard",
  props: {
    image: String,
    title: String,
    url: String,
    id: String
  },
  setup(props) {
    return {
      open: () => window.open(props.url)
    };
  }
});
