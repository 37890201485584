import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "./views/Home.vue";
import store from "./store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    props: true
  },
  {
    path: "/gtc",
    name: "Gtc",
    component: () => import("./views/Gtc.vue"),
    meta: {
      notAuth: true
    }
  },
  {
    path: "/learn",
    name: "Learn",
    component: () => import("./views/Learn.vue"),
    meta: {
      notAuth: true
    }
  },
  {
    path: "/mentors",
    name: "Mentors",
    component: () => import("./components/LandPage.vue"),
    meta: {
      notAuth: true
    }
  },
  {
    path: "/validation/:code",
    name: "Validation",
    component: () => import("./views/EmailValidation.vue"),
    meta: {
      notAuth: true
    }
  },
  {
    path: "/reset-password/:code",
    name: "ResetPassword",
    component: () => import("./views/ResetPassword.vue"),
    meta: {
      notAuth: true
    }
  },
  {
    path: "/learn",
    name: "Learn",
    component: () => import("./views/Learn.vue")
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("./views/Profile.vue")
  },
  {
    path: "/user/:username",
    name: "UserProfile",
    component: () => import("./views/UserProfile.vue")
  },
  {
    path: "/ctf/:id",
    name: "AvailableUsersList",
    component: () => import("./views/AvailableUsersList.vue"),
    props: true
  },
  {
    path: "/inbox",
    name: "Inbox",
    component: () => import("./views/Inbox.vue"),
    props: true
  },
  {
    path: "/inbox/:username",
    name: "InboxId",
    component: () => import("./views/Inbox.vue")
  },
  {
    path: "/404",
    name: "404",
    component: () => import("./views/Error404.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    name: "redirect",
    redirect: { name: "404" }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeResolve(async (to, from, next) => {
  const noRequireAuth = to.matched.some(record => record.meta.notAuth);
  const isLogged = store.state.user == null ? false : true;

  if (
    (isLogged || to.path == "/" || to.path == "/404" || to.path == "/learn") &&
    !noRequireAuth
  ) {
    next();
  } else if (noRequireAuth && !isLogged) {
    next();
  } else {
    next({ path: "/" });
  }
});

export default router;
