import { createStore } from "vuex";
import { Conversation, Message, StoreState, User } from "./constants/types";

const store = createStore({
  state() {
    return {
      user: null,
      step: null,
      messages: [],
      conversations: [],
      unreadMessages: []
    };
  },
  mutations: {
    setUser(state: StoreState, user: User) {
      state.user = user;
    },
    loadMessages(state: StoreState, messages: Message[]) {
      state.messages = messages;
    },
    loadConversation(state: StoreState, conversations: Conversation[]) {
      state.conversations = conversations;
    },
    addMessage(state: StoreState, newMessage: Message) {
      const oldMessages = state.messages as Message[];
      state.messages = [...oldMessages, newMessage];
    },
    unreadMessages(state: StoreState, unreadMessages: Message[]) {
      state.unreadMessages = unreadMessages;
    },
    updateStep(state: StoreState, step: number) {
      state.step = step;
    }
  }
});

export default store;
