export default [
  {
    title: "The CTF list ",
    text: "Here you can discover new CTFs",
    isStep: 0
  },
  {
    title: "CTF registration",
    text: "Register to the CTFs to start find teammates",
    isStep: 1
  },
  {
    title: "Complete your profile",
    text: "To received more message, don’t forget to complete your profile.",
    isStep: 2
  },
  {
    title: "Start chatting",
    text: "Chat with your future CTF’s teammates.",
    isStep: 3
  },
  {
    title: "Inbox",
    text: "Find your sent messages here.",
    isStep: 4
  }
];
