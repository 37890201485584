const token = localStorage.getItem("token");

export const headers = {
  authorization: token == null ? null : `Bearer ${token}`
};

export function getLink(hashedUsername: string): string {
  return (
    "https://www.gravatar.com/avatar/" + hashedUsername + "?s=80&d=retro&r=g"
  );
}

export function capitalize(word: string): string {
  return (
    word
      .trim()
      .charAt(0)
      .toUpperCase() +
    word
      .trim()
      .toLowerCase()
      .slice(1)
  );
}
