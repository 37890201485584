import { createApp, provide, h } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  ApolloClient,
  ApolloLink,
  concat,
  createHttpLink,
  InMemoryCache,
  split
} from "@apollo/client/core";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { WebSocketLink } from "@apollo/client/link/ws";
import {
  getMainDefinition,
  offsetLimitPagination
} from "@apollo/client/utilities";
import { headers } from "./utils/utils";
import "./styles.scss";

if (
  window.location.hostname == "cohackers.co" &&
  location.protocol !== "https:"
) {
  location.protocol = "https:";
}

const httpLink = createHttpLink({
  uri: `${process.env.VUE_APP_HTTP}`
});

const wsLink = new WebSocketLink({
  uri: `${process.env.VUE_APP_WS}`,
  options: {
    reconnect: true,
    connectionParams: headers
  }
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({ headers });
  return forward(operation);
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        findByCtf: offsetLimitPagination(["id"]),
        unreadMessages: {
          merge(existing, incoming) {
            return incoming;
          }
        },
        messages: {
          merge(existing, incoming) {
            return incoming;
          }
        }
      }
    }
  }
});

const apolloClient = new ApolloClient({
  link: concat(authMiddleware, link),
  cache
});

createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render() {
    return h(App);
  }
})
  .use(router)
  .use(store)
  .mount("#app");
