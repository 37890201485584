
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
import { useMutation, useQuery, useSubscription } from "@vue/apollo-composable";

import Navbar from "./components/Navbar.vue";
import SignUpDialog from "./components/SignUpDialog.vue";
import LoginDialog from "./components/LoginDialog.vue";
import ResetDialog from "./components/ResetPasswordDialog.vue";

import { Message } from "./constants/types";
import { useRoute } from "vue-router";
import { FIND_USERS, WHOAMI_QUERY } from "./constants/gql/user";
import {
  MESSAGES_QUERY,
  MESSAGES_SUBSCRIPTION,
  UNREAD_MESSAGES_QUERY,
  UPDATE_MESSAGE,
  READ_MESSAGES_SUBSCRIPTION
} from "./constants/gql/message";

export default defineComponent({
  components: {
    Navbar,
    SignUpDialog,
    LoginDialog,
    ResetDialog
  },
  setup() {
    const isActiveSignUpDialog = ref<boolean>(false);
    const isActiveLoginDialog = ref<boolean>(false);
    const isActiveResetDialog = ref<boolean>(false);

    const store = useStore();
    const route = useRoute();

    const { onResult: onWhoamiResult, loading } = useQuery(WHOAMI_QUERY);
    const { onResult: onMessageResult } = useQuery(MESSAGES_QUERY);
    const { result: subResult } = useSubscription(MESSAGES_SUBSCRIPTION);
    const { mutate: updateMessage } = useMutation(UPDATE_MESSAGE);
    const { onResult: onUnreadMessageResult, refetch } = useQuery(
      UNREAD_MESSAGES_QUERY
    );
    const { onResult: onSubReadMessages } = useSubscription(
      READ_MESSAGES_SUBSCRIPTION
    );

    const user = computed(() => store.state.user);
    const messages = computed(() => store.state.messages);

    onWhoamiResult(({ data }) => {
      if (data != null) {
        store.commit("setUser", data.whoami);
      }
    });

    onMessageResult(({ data }) => {
      if (data != null) {
        store.commit("loadMessages", data.messages);
      }
    });

    onSubReadMessages(({ data }) => {
      if (data != null) {
        store.commit("loadMessages", data.readMessages);
      }
    });

    onUnreadMessageResult(({ data }) => {
      if (data != null) {
        store.commit("unreadMessages", data.unreadMessages);
      }
    });

    const conversations = computed(() =>
      messages.value.reduce((acc: string[], m: Message) => {
        if (m.from != user.value.username && !acc.includes(m.from)) {
          acc.unshift(m.from);
        } else if (m.to != user.value.username && !acc.includes(m.to)) {
          acc.unshift(m.to);
        }
        return acc;
      }, [])
    );

    const { onResult: onFindUsersResult } = useQuery(FIND_USERS, {
      usernames: conversations
    });

    onFindUsersResult(({ data }) => {
      if (data) {
        store.commit("loadConversation", data.findUsers);
      }
    });

    watch(subResult, async data => {
      if (data && data.messageAdded.from === route.params.username) {
        await updateMessage({
          from: route.params.username
        });
      }
      refetch();
      store.commit("addMessage", data.messageAdded);
    });

    const toggleSignUpDialog = () => {
      isActiveSignUpDialog.value = !isActiveSignUpDialog.value;
    };

    const toggleLoginDialog = () => {
      isActiveLoginDialog.value = !isActiveLoginDialog.value;
    };
    const toggleResetDialog = () => {
      isActiveResetDialog.value = !isActiveResetDialog.value;
    };

    return {
      loading,
      user,
      isActiveSignUpDialog,
      toggleSignUpDialog,
      isActiveLoginDialog,
      toggleLoginDialog,
      isActiveResetDialog,
      toggleResetDialog,
      refetch
    };
  }
});
